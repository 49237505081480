.widthAuto {
    min-width: 375px;
    max-width: 600px;
    margin: 0 auto;
}
.banner {
    /* background-image: url(./static/img/M/bg.png); */
    /* background-repeat: no-repeat;
    background-size: 100%; */
    /* height: 3.75rem; */
    color: #000;
}
.banner .logo {
    height: 0.6rem;
    line-height: .6rem;
    text-align: left;
}
.banner .logo img{
    width: 1.2rem;
}
.banner .width80>img {
    width: 80%;
    margin-left: 10%;
}
.banner header {
    margin-top: .3rem;
    text-align: center;
    font-size: .16rem;
}
.banner .width80 >p{
    margin-top:0.2rem;
    font-size: .12rem;
    line-height: .16rem;
}
.banner header p {
    font-size: .12rem;
}
.banner .platform {
    height: .24rem;
    margin-bottom: .3rem;
}
.banner .platform span{
    display: inline-block;
    width: .24rem;
    height: .24rem;
    line-height: .24rem;
    text-align: center;
    margin-right: .09rem;
    background-color: #FFF;
    border-radius: 50%;
    font-size: .22rem;
}
.banner .iconffbb {
    color: #3b74ee;
}
.banner .iconttww {
    color: #5aadea;
}
.banner .iconinss {
    color: #d9427e;
}
.banner .iconlinkedin {
    color: #2e66bb;
}
.banner .iconyoutube1 {
    color: #e83323;
}
.banner a {
    margin-left: 10%;
    display: inline-block;
    height:.35rem;
    line-height: .35rem;
    border-radius: .2rem;
    width: 80%;
    cursor: pointer;
    background: #eb732e;
    color: #fff;
    text-align: center;
    text-decoration: none;
}
#emailRegister, #phoneRegister {
    position: relative;
    margin-top: .1rem;
    height: 3rem;
    background-size: 100%;
    background-repeat: no-repeat;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}
#emailRegister {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    height: 2.8rem;
    background: #fff;
    border-radius: 5px;
    margin-top:.5rem;
}
#emailRegister .imgInfo {
    width: .9rem;
    height: .9rem;
    margin: 0 auto;
}
#emailRegister .imgInfo img{
    margin-top:.1rem;
    width: 100%;
}
#emailRegister ul li {
    text-align: center;
    margin-top: 1rem 0;
}
#emailRegister ul li.sendEmail {
    font-size: .14rem;
    color: #2b72f3;
    font-weight: 600;
    margin-top: .2rem;
}
#emailRegister ul li.userEmail {
    font-size: .12rem;
    color: #242424;
    border: 1px dashed #2b72f3;
    background-color: #f6f9fe;
}
#emailRegister ul li.userEmail p {
    margin: .08rem 0;
}
#emailRegister ul li.tipEmail {
    font-size: 0.09rem;
    color: #909599;
    background-color: #fbfbfb;
    padding: .08rem 0;
    margin-top: .2rem;
}
#emailRegister ul li.tipEmail p {
    margin: 0;
}
#phoneRegister {
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    /* background-image: url(./static/img/phone/zhucebeijing.png); */
}
.register header {
    /* position: absolute;
    left: 5%;
    top: 0.21rem; */
    margin-left:5%;
    margin-top:0.21rem;
    width: 1.76rem;
    height:0.155rem;
    line-height: 0.155rem;
    text-align: center;
    font-size: 0.16rem;
    color: #000;
    border-left:.02rem solid #eb732e;
}
.register .content {
    flex: 1;
}
.register .content .tel span{
    display: flex;
    justify-content: space-between;
    color:#666666;
    font-size: .12rem;
}
.register .content .tel input{
    -webkit-appearance: none;
    border:none;
    width: 100%;
    border:1px solid #dddddd;
    border-radius: 5px;
    height:0.4rem;
    padding-left:0.10rem;
    color:#333333;
    outline: none;
    margin-top:.12rem;
}
 .register .content .passwd {
     margin-top:.22rem;
     color:#000;
    /* display: flex; */
    /* align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 0.91rem;
    border-bottom: 1px solid #909599; */
}
.register .content .passwd span {
    color:#666666;
    font-size: .12rem;
}
.register .content .passwd >div{
    margin-top: .12rem;
    border:1px solid #dddddd;
    display: flex;
    align-items: center;
    height: 0.4rem;
    border-radius: 5px;
}
.register .content .passwd input {
    -webkit-appearance: none;
    border:none;
    width: 65%;
    height: .2rem;
    font-size: .14rem;
    padding-left:0.10rem;
    border-right:1px solid #ddd;
    color:#333333;
    outline: none;
}
.register .content .passwd input::-webkit-input-placeholder{
    font-size: .14rem;
}
.register .content .passwd button {
    padding: 0.08rem;
    border: none;
    outline: none;
    font-size: .14rem;
    color: #999999;
    background: none;
    border-radius: 3px;
    margin-bottom: 0.03rem;
    min-width: 0.38rem;
    margin-left:.1rem;
}
.register .content .mail {
    padding-top: .07rem;
    color: #2b72f3;
    font-size: .12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.register .content .mail p {
    display: flex;
    align-items: center;
    color:#999999;
    cursor: pointer;
    margin-top:9px;
}
.register .content .mail p input{
    margin:0;
}
.register .content .mail p a{
    color:#999999;
}
.register .content .error{
    color: #f4523b!important;
}
.register .content .registration{
    text-align: center;
    margin-top: 0.185rem;
    color:#fff;
}
.register .content .registration span{
    display: inline-block;
    height:.33rem;
    line-height: .33rem;
    border-radius: .2rem;
    width: 80%;
    cursor: pointer;
    background-image: linear-gradient(to right,#3679ff,#39a3ff);
}
.register .next {
    position: absolute;
    bottom: .19rem;
    left: 50%;
    margin-left: -0.35rem;
    width: 0.80rem;
    height: 0.40rem;
    line-height: 0.40rem;
    text-align: center;
    color: #FFF;
    cursor: pointer;
}
.register header,#emailRegister header{
    display: inline-block;
}
#emailRegister .content {
    margin-top: 0.2rem;
}
#emailRegister .next{
    bottom: 0.11rem;
}
#emailRegister .content .tel #email{
    width: 100%;
}
#emailRegister .registration span{
    display: inline-block;
    height:.33rem;
    line-height: .33rem;
    border-radius: .2rem;
    width: 80%;
    cursor: pointer;
    background: #eb732e;
    /* background-image: linear-gradient(to right,#3679ff,#39a3ff); */
}
#phoneRegister .content {
    margin-top: 0.2rem;
}
#phoneRegister .tel >span{
    display: flex;
    justify-content: space-between;
    font-size: .12rem;
    color:#666666;
}
.register .mailBtnLeft {
    width:0;
    height:0;
    border-width: 0.20rem 0.268rem 0.20rem 0;
    border-style:solid;
    border-color:transparent #5eb4ad transparent transparent;/*透明 灰 透明 透明 */
    position: absolute;
    bottom: .001rem;
    left: -0.258rem;
}
.register .mailBtnRight {
    width:0;
    height:0;
    border-width: 0.20rem 0 0.20rem 0.268rem;
    border-style:solid;
    border-color:transparent transparent transparent #5eb4ad;/*透明 透明 透明 灰*/
    position: absolute;
    bottom: .001rem;
    right: -0.266rem;
}
.downArrow {
    height: 0.81rem;
    text-align: center;
    line-height: 0.81rem;
}
.downArrow img {
    display: inline-block;
    width: .19rem;
    height: .28rem;
}
.brandOS header {
    font-size: .18rem;
    font-weight: 900;
    color: #5d5d5d;
}
.brandOS img {
    margin-top: .1rem;
    width: 100%;
}
.Features {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.04rem;
    /* line-height: 1.04rem; */
    text-align: center;
}
.Features .FeaturesStr {
    font-size: 0.14rem;
}
.RotateBox{
    width: 5px;
    height: 5px;
    display: inline-block;
    margin:0 5px;
    border:1px solid #ccc;
    transform: rotate(45deg);
}
.Features .FeaturesLeft, .Features .FeaturesRight {
    display: inline-block;
    width: 30%;
    height: .01rem;
}
.Features .FeaturesLeft, .Features .FeaturesRight {
    background-size: 100%;
    background-repeat: no-repeat;
    height: .01rem;
}
.Features .FeaturesLeft {
    background-image: linear-gradient(to right,#fff,#d3d3d3);
    /* background-image: url(./static/img/phone/rightLine.png); */
}
.Features .FeaturesRight {
    background-image: linear-gradient(to left,#fff,#d3d3d3);
    /* background-image: url(./static/img/phone/leftLine.png); */
}
.SocialMedia {
    height: 6rem;
}
.SocialMedia img{
    width: 100%;
}
.AddTool {
    height: 6.42rem;
}
.UserCare {
    height: 5.42rem;
}
.RichFunction{
    height: 6.4rem;
}
.SocialMedia header, .RichFunction header, .AddTool header{
    position: relative;
    margin-bottom: .2rem;
}
.SocialMedia header .SocialMediaStr{
    color:#5ccc95;
    
}
.SocialMedia header .SocialMediaStr i{
        margin-right:.08rem;
}
.SocialMedia header .SocialMediaStr, .RichFunction header .RichFunctionStr, .AddTool header .AddToolStr, .UserCare header .UserCareStr{
    font-size: 0.15rem;
    font-weight: 900;
}

.SocialMedia ul li, .RichFunction  ul li, .AddTool ul li,.UserCare ul li {
    margin-bottom: .2rem;
    line-height: .2rem;
    font-size: .13rem;
    color: #999999;
}
.AddTool header {
    text-align: left;
    color:#44b8f8;
}
.AddTool ul li strong {
    font-size: .12rem;
    color: #909599;
    font-weight: normal;
}

.RichFunction header .RichFunctionStr {
    text-align: left;
    color:#5bd4c7;
}
.RichFunction header .RichFunctionLine {
    position: absolute;
    right: 0;
}
.UserCare header {
    color:#496bea
}
.bottomLine img,.RichFunction img, .AddTool img, .UserCare img{
    width: 100%;
}
.registerBottom {
    margin-bottom: .5rem;
}

.width80 {
    width: 90%;
    margin: 0 auto;
}
.width75 {
    width: 75%;
    margin: 0 auto;
}
.specialWidth90 {
    width: 90%;
    margin: 0.1rem auto 0;
}

.register .content .Agreement {
    color: #909599;
}
.register .content .Agreement a {
    color: #242424;
}
.register .content .Agreement span {
    padding-left: 0.025rem;
    padding-top: 0.02rem;
    font-size: 0.12rem;
}
.register .content .Agreement a:hover {
    text-decoration: none;
}


/* 小屏幕（平板，大于等于 414px) */
@media (min-width: 414px) {
    
}

/* 中等屏幕（桌面显示器，大于等于 768px） */
@media (min-width: 768px) {
    .RichFunction {
        height: 7.7rem;
    }
    .AddTool {
        height: 7.1rem;
    }
    .registerBottom #emailRegister {
        margin-top: 1.6rem;
    }
    .register .next {
        height: 0.66rem;
        line-height: 0.66rem;
        bottom: .1rem;
        width: 1.60rem;
        margin-left: -0.7rem;
    }
    .register .next p {
        margin: 0
    }
    .register header {
        left: 0.68rem;
        top: 0.3rem;
    }
}